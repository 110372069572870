import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {decrement, increment} from "../features/counterSlice";

function Home() {
  const title = 'React Bootstrap Template';

  const navigate = useNavigate();
  const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch()

  return (
    <div className="container-fluid text-center">
      <button className={"btn btn-light"} onClick={() => dispatch(increment())}>Increase</button>
      <h2>{count}</h2>
      <button className={"btn btn-light"} onClick={() => dispatch(decrement())}>Decrease</button>
    </div>
  );
}

export default Home;
