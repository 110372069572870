import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_giaodichnn,
  format_price,
  format_rank,
  get_floor,
  getIncreaseRoom,
  has_margin,
  is_v30,
  map_industry,
  margin_remain,
} from '../common/util';
import CTTService from '../services/CTTService';
import LoadingSpinner from './LoadingSpinner';
import TickersIndustry from './TickersIndustry';

function ModalTickerDetail(props) {
  console.log(props);
  const [isLoading, setLoad] = useState(true);
  const [ticker, setTicker] = useState({});
  const [industry, setIndustry] = useState(<></>);
  const [showValueBtnIndustry, setShowValueBtnIndustry] = useState(true);

  useEffect(() => {
    CTTService.get_ticker(props.ticker.ticker).then(r => {
      if (r.tickerData === null) {
        alert('Not found ' + props.ticker.ticker)
        return
      }
      setTicker(r)
      setLoad(false)
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  async function show_industry() {
    setShowValueBtnIndustry(false)
    setIndustry(<><TickersIndustry industry={ticker.industry}/></>)
  }

  let margin_html = <span className={"no-margin"}></span>;
  if (has_margin(props.ticker.ticker)) {
    margin_html = <span className={"has-margin"}></span>
  }

  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      autoFocus={true}
      size={'xl'}
    >
      <Modal.Header>
        <div className="col-3">
          <h4>{margin_html}{ticker.ticker}{is_v30(ticker.ticker) ? '*' : ''}<sup>{get_floor(ticker.ticker)}</sup></h4>
        </div>
        <span className={"col-2" + class_price_percent(ticker.pricePercent)}>{ticker.priceFlat} ({format_float(ticker.pricePercent * 100)}%)</span>
        {ticker.company}
        <br/>{map_industry(ticker.industry)}
        <button type="button" className="btn-close" onClick={props.close}></button>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="chart-tab" data-bs-toggle="tab" data-bs-target="#chart" type="button" role="tab" aria-controls="chart" aria-selected="false">chart</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="summary-tab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true">summary</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="signal-tab" data-bs-toggle="tab" data-bs-target="#signal" type="button" role="tab" aria-controls="signal" aria-selected="false">signal</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="industry-tab" data-bs-toggle="tab" data-bs-target="#industry" type="button" role="tab" aria-controls="industry" aria-selected="false">industry</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade" id="summary" role="tabpanel" aria-labelledby="summary-tab">
            <div className="table-responsive row">
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                    <tr>
                      <td className="ticker-modal-table text-start w-50">Equity</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{format_float(ticker.value.equity / 1000000000)} tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start w-50">Value Equity</td>
                      <td className="text-right-overview empty-black text-end col-md-6">
                        {ticker.value.value_equity.min}-{ticker.value.value_equity.max} &nbsp;({getIncreaseRoom(ticker.value.value_equity.room)})
                      </td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Assets</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{format_float(ticker.value.asset / 1000000000)} tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Value Assets</td>
                      <td className="text-right-overview empty-black text-end col-md-6">
                        {ticker.value.value_asset.min}-{ticker.value.value_asset.max}&nbsp;({getIncreaseRoom(ticker.value.value_asset.room)})</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Margin room</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{format_float(margin_remain(ticker.ticker) / 1000000000)} tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Vốn hóa</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{ ticker.vonhoa.toLocaleString() } tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">EPS</td>
                      <td className="text-right-overview empty-black text-end">{ ticker.eps_pha_loang }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">P/E</td>
                      <td className="text-right-overview empty-black text-end">{ ticker.pe }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">ROE</td>
                      <td className="text-right-overview text-end"><span >{ format_float(ticker.roe * 100) }%</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">TB KLGD</td>
                      <td className="text-right-overview empty-black text-end">{ ticker.tb_klgd.toLocaleString() }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Giao dịch NN</td>
                      <td className="positive text-end"><span className={class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn } tỷ</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Nợ/VCSH</td>
                      <td className="text-end empty-black"><span >{ format_float(ticker.noVCSH) }</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">+/- 5 phiên</td>
                      <td className="text-end"><span className={class_text_danger(ticker.pricePercent5)}>{ format_float(ticker.pricePercent5 * 100) }%</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">+/- 60 phiên</td>
                      <td className="positive text-end"><span className={class_text_danger(ticker.pricePercent60)}>{ format_float(ticker.pricePercent60 * 100) }%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">SLCP</td>
                    <td className="text-right-overview empty-black text-end">
                      { ticker.slcp.toLocaleString() }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Book value
                    </td>
                    <td className="text-right-overview empty-black text-end">
                      { ticker.gia_tri_so_sach }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">P/B</td>
                    <td className="text-right-overview empty-black text-end">
                      { format_float(ticker.pb) }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">ROA</td>
                    <td className="text-end"><span >{ format_float(ticker.roa * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">TB GTGD</td>
                    <td className="text-right-overview empty-black text-end">
                      { format_float(ticker.tbgtdd, 2) } tỷ
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Sở Hữu NN</td>
                    <td className="negative text-end">
                      <span className={class_text_danger(ticker.sohuungoai)}>{ format_float(ticker.sohuungoai) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Biên lợi nhuận</td>
                    <td className="positive text-end">
                      <span className={class_text_danger(ticker.bienloinhuan)}>{ format_float(ticker.bienloinhuan * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">+/- 20 phiên</td>
                    <td className="text-end">
                      <span className={class_text_danger(ticker.pricePercent20)}>{ format_float(ticker.pricePercent20 * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Update BC</td>
                    <td className="text-end">
                      <span >{ ticker.bctc }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="signal" role="tabpanel" aria-labelledby="signal-tab">
            <div className="table-responsive row">
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">Điểm nền tảng</td>
                    <td className="positive text-end"><span >{ format_float(ticker.diem_tl) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">FS Rating</td>
                    <td className="text-right-overview empty-black text-end">
                      <span className={class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">RS Rating</td>
                    <td className="text-right-overview empty-black text-end">
                      <span >{ ticker.rs_rating }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback</td>
                    <td className="signal large text-end">
                      <span>{ ticker.pullback_20 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback 10</td>
                    <td className="signal large text-end">
                      <span>{ ticker.pullback_10 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 5</td>
                    <td className="signal large text-end">
                      <span>{ ticker.break5 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 20</td>
                    <td className="signal large text-end">
                      <span>{ ticker.break20 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 60</td>
                    <td className="signal large text-end">
                      <span>{ ticker.break60 }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">Điểm tín hiệu</td>
                    <td className=" positive text-end">
                      <span className={class_text_danger(ticker.point)}>{ format_float(ticker.point) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Top FS</td>
                    <td className="empty-black text-end">
                      <span className="align-left ">{ ticker.fs_rating_rank }</span>
                      <span className="negative"><i className="mdi mdi-arrow-down"></i>
                            <span className=""> { format_rank(ticker.ct_fs_rating_rank) }</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Top RS</td>
                    <td className="empty-black text-end">
                      <span className="align-left ">{ ticker.rs_rating_rank }</span>
                      <span className="positive"><i className="mdi mdi-arrow-up"></i>
                            <span className=""> { format_rank(ticker.ct_rs_rating_rank) }</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback Price</td>
                    <td className="signal large text-end">
                      <span>{ ticker.pullback_20_price }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback 10 Price</td>
                    <td className="signal large text-end">
                      <span>{ ticker.pullback_10_price }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Wait Signal</td>
                    <td className="signal large text-end">
                      <span>{ ticker.signal }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Wait Price</td>
                    <td className="signal large text-end">
                      <span>{ format_price(ticker.wait_price) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Điểm số chờ</td>
                    <td className="signal large text-end">
                      <span>{ ticker.wait_score }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="table table-bordered table-hover table-right ticker-modal-signal-table signal-table">
              <thead>
              <tr>
                <td colSpan="6">Last 10 sessions</td>
              </tr>
              <tr className="header-table">
                <td>Date</td>
                <td>Price</td>
                <td>+/-</td>
                <td>Signal</td>
                <td>PriceAction</td>
                <td>NN</td>
              </tr>
              </thead>
              <tbody>
              {
                ticker.tendays && ticker.tendays.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td className="align-center">{ format_date(row.date) }</td>
                      <td className="text-end">{ format_price(row.priceFlat) }</td>
                      <td className={"text-end" + class_price_percent(row.pricePercent)}>{ format_float(row.pricePercent * 100) } %</td>
                      <td className="normal align-center">{ row.signal }</td>
                      <td className="text-end">{ format_price(row.gia_thuc_hien) }</td>
                      <td className={"negative text-end" + class_text_danger(row.giaodichnn)}>{ format_giaodichnn(row.giaodichnn) }</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          <div className="tab-pane fade" id="industry" role="tabpanel" aria-labelledby="industry-tab">
            {industry}{showValueBtnIndustry && <button className="btn btn-outline-secondary btn-sm" onClick={() => show_industry()}>show</button>}
          </div>
          <div className="tab-pane fade show active" id="chart" role="tabpanel" aria-labelledby="chart-tab">
            <iframe src={`https://chart.finbox.vn/mobile-chart?popup=true&symbol=${ticker.ticker}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} style={{width: "100%", height: "680px", border: "none"}}/>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

}

export default ModalTickerDetail;
