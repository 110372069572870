import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  getPreviousWeekdays,
  isNullOrUndefined,
  map_industry,
  merge_arrays,
  sortArrayByProperty,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import {format} from "date-fns";
import TDTrend from "../components/TDTrend";
import TDTickerVol from "../components/TDTickerVol";
import TDSignal from "../components/TDSignal";
import TDPrice from "../components/TDPrice";
import ModalService from "../services/ModalService";
import ModalSimple from "../components/ModalSimple";
import CTTService from "../services/CTTService";
import TDGDNN from "../components/TDGDNN";


function TableHeadIndex() {
  return (
    <thead>
      <tr>
        <th>date</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th>trend</th>
        <th>fs_rating</th>
        <th>gdnn</th>
      </tr>
    </thead>
  )
}

export function TableHeadBuy() {
  return (
    <thead>
      <tr>
        <th className="th-ticker-vol">ticker</th>
        <th className="th-signal">signal</th>
        <th className="th-price">price</th>
        <th>gdnn</th>
      </tr>
    </thead>
  )
}

export function TableHeadBase() {
  return (
    <thead>
      <tr>
        <th style={{width:"50%"}}>ticker</th>
        <th style={{width:"30%"}}>signal</th>
        <th style={{width:"20%"}}>price</th>
      </tr>
    </thead>
  )
}

function TableBody0({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <td className="text-end" onClick={() => ModalService.open(ModalSimple, {ticker})}>{ format_date(ticker.date) }</td>
          <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
          <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
          <TDTrend ticker={ticker} />
          <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
          <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn }</td>
        </tr>
      ))
    }
    </tbody>
  )
}

export function TableBodyBuy({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker} highlight_own={false}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
          <TDGDNN ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

export function TableBodyBase({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyWait({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.wait_signal} price={ticker.wait_price}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyBasis({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyCashFlow({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyVolume({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}


function TableBodyForeign({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={'NN: ' + ticker.giaodichnn}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyBreak({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyPrice({ rows }) {
  return (
    <tbody>
    {
      rows&& rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ format_float(ticker.pricePercent * 100) + ' %'}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodySector({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((ticker, i) => (
        <tr key={ i }>
          <td style={{minWidth: 100}}>{ map_industry(ticker.ticker) }</td>
          <TDSignal signal={ticker.signal} price={'NN: ' + ticker.giaodichnn}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}
function TableBodyPullback({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.pullback} price={ticker.pullback_price} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
          <TDGDNN ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function Summary() {
  const title = 'Summary';
  const tabs = [
    { target: 'trend' },
    { target: 'buy', active: 'active' },
    { target: 'sell' },
    { target: 'wait_buy' },
    { target: 'wait_sell' },
    { target: 'basis' },
    { target: 'cash_flow' },
    { target: 'volume' },
    { target: 'foreign' },
    { target: 'break' },
    { target: 'price' },
    { target: 'sector' },
  ];
  const [isLoading, setLoad] = useState(true);
  const [option, setOption] = useState(0);
  const [data, setData] = useState({});
  const [daySelect, setDaySelect] = useState([]);

  useEffect(() => {
    CTTService.fetch_summary(option).then(response => {
      let temp = response
      temp.marketTwoData.buy = merge_arrays(response.marketTwoData.buy_standard, response.marketTwoData.buy_normal)
      temp.marketTwoData.buy = sortArrayByProperty(temp.marketTwoData.buy, 'industry')

      temp.marketTwoData.sell = merge_arrays(response.marketTwoData.sell_standard, response.marketTwoData.sell_normal)
      temp.marketTwoData.sell = sortArrayByProperty(temp.marketTwoData.sell, 'industry')

      temp.marketTwoData.wait_buy = merge_arrays(response.marketTwoData.wait_buy_standard, response.marketTwoData.wait_buy_normal)
      temp.marketTwoData.wait_buy = sortArrayByProperty(temp.marketTwoData.wait_buy, 'industry', true)

      temp.marketTwoData.wait_sell = merge_arrays(response.marketTwoData.wait_sell_standard, response.marketTwoData.wait_sell_normal)
      temp.marketTwoData.wait_sell = sortArrayByProperty(temp.marketTwoData.wait_sell, 'industry')

      temp.marketTwoData.basis = merge_arrays(response.marketTwoData.basis_standard, response.marketTwoData.basis_normal)
      temp.marketTwoData.basis = sortArrayByProperty(temp.marketTwoData.basis, 'pricePercent', true)

      temp.marketTwoData.cash_flow = merge_arrays(response.marketTwoData.fs_standard, response.marketTwoData.fs_normal)
      temp.marketTwoData.cash_flow = sortArrayByProperty(temp.marketTwoData.cash_flow, 'fs_rating', true)

      temp.marketTwoData.volume = merge_arrays(response.marketTwoData.volume_standard, response.marketTwoData.volume_normal)
      temp.marketTwoData.volume = sortArrayByProperty(temp.marketTwoData.volume, 'fs_rating', true)

      temp.marketTwoData.break_up = merge_arrays(response.marketTwoData.break_up_standard_5, response.marketTwoData.break_up_normal_5)
      temp.marketTwoData.break_up = sortArrayByProperty(temp.marketTwoData.break_up, 'pricePercent', true)

      temp.marketTwoData.foreign_buy = response.marketTwoData.foreign_buy
      temp.marketTwoData.foreign_sell = response.marketTwoData.foreign_sell

      temp.marketTwoData.break_down = merge_arrays(response.marketTwoData.break_down_standard_5, response.marketTwoData.break_down_normal_5)
      temp.marketTwoData.break_down = sortArrayByProperty(temp.marketTwoData.break_down, 'pricePercent')

      temp.marketTwoData.price_up = merge_arrays(response.marketTwoData.price_up_standard, response.marketTwoData.price_up_normal)
      temp.marketTwoData.price_up = sortArrayByProperty(temp.marketTwoData.price_up, 'pricePercent', true)

      temp.marketTwoData.price_down = merge_arrays(response.marketTwoData.price_down_standard, response.marketTwoData.price_down_normal)
      temp.marketTwoData.price_down = sortArrayByProperty(temp.marketTwoData.price_down, 'pricePercent')

      temp.marketTwoData.sector_increase = response.marketTwoData.sector_increase
      temp.marketTwoData.sector_decrease = response.marketTwoData.sector_decrease

      temp.marketTwoData.pullback = merge_arrays(
        response.marketTwoData.pullback_10_standard,
        response.marketTwoData.pullback_20_standard,
        response.marketTwoData.pullback_10_normal,
        response.marketTwoData.pullback_20_normal)
      temp.marketTwoData.pullback = sortArrayByProperty(temp.marketTwoData.pullback, 'industry', true)
      temp.marketTwoData.pullback = temp.marketTwoData.pullback.map(item => {
          if (!isNullOrUndefined(item['pullback_10']) && !isNullOrUndefined(item['pullback_20'])) {
            item['pullback'] = 'PULLBACK 10/20'
            item['pullback_price'] = `${format_price((item['pullback_10_price'] + item['pullback_20_price']) * 0.5)}`
          } else if (!isNullOrUndefined(item['pullback_10'])) {
            item['pullback'] = 'PULLBACK 10'
            item['pullback_price'] = format_price(item['pullback_10_price'])
          } else if (!isNullOrUndefined(item['pullback_20'])) {
            item['pullback'] = 'PULLBACK 20'
            item['pullback_price'] = format_price(item['pullback_20_price'])
          }
          return item
        })
      setData(temp)
      setLoad(false)
    });
    const daySelect = getPreviousWeekdays(new Date(), 6)
    setDaySelect(daySelect.map(item => {
      return format(item, 'EEEEEE dd/MM').toUpperCase();
    }))
  }, [option]);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  function changeOption(e) {
    console.log(e.target.value)
    setOption(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <select className="form-select form-select-sm mt-3" style={{margin:'auto', width:'auto'}} aria-label=".form-select-sm" onChange={(e) => changeOption(e)}>
          {
            daySelect.map((item, i) => (
              <option key={i} value={i}>{item}</option>
            ))
          }
        </select>
        <ul className="nav nav-pills" id="myTab" role="tablist">
          {
            tabs.map((item, i) => (
              <li key={i} className="nav-item" role="presentation">
                <button className={`nav-link ${item.active}`} id={`${item.target}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.target}`} type="button" role="tab" aria-controls={item.target} aria-selected="true">{item.target}</button>
              </li>
            ))
          }
        </ul>
        <div className="tab-content">

          <div className="tab-pane fade" id="trend" role="tabpanel" aria-labelledby="trend-tab">
            <button className="btn btn-secondary" onClick={() => ModalService.open(ModalSimple, {ticker: {ticker: 'VN30'}})}>VN30</button>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <TableHeadIndex />
                <TableBody0 rows={data.marketOneData.VNINDEX} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show active" id="buy" role="tabpanel" aria-labelledby="buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <caption>BUY</caption>
                <TableHeadBuy />
                <TableBodyBuy rows={data.marketTwoData.buy} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>PULLBACK</caption>
                <TableHeadBuy />
                <TableBodyPullback rows={data.marketTwoData.pullback} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="sell" role="tabpanel" aria-labelledby="sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyBase rows={data.marketTwoData.sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="wait_buy" role="tabpanel" aria-labelledby="wait_buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_buy} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="wait_sell" role="tabpanel" aria-labelledby="wait_sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="basis" role="tabpanel" aria-labelledby="basis-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyBasis rows={data.marketTwoData.basis} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="cash_flow" role="tabpanel" aria-labelledby="cash_flow-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyCashFlow rows={data.marketTwoData.cash_flow} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="volume" role="tabpanel" aria-labelledby="volume-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyVolume rows={data.marketTwoData.volume} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="foreign" role="tabpanel" aria-labelledby="foreign-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>BUY</caption>
                <TableHeadBase />
                <TableBodyForeign rows={data.marketTwoData.foreign_buy} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>SELL</caption>
                <TableHeadBase />
                <TableBodyForeign rows={data.marketTwoData.foreign_sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="break" role="tabpanel" aria-labelledby="break-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHeadBase />
                <TableBodyBreak rows={data.marketTwoData.break_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHeadBase />
                <TableBodyBreak rows={data.marketTwoData.break_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHeadBase />
                <TableBodyPrice rows={data.marketTwoData.price_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHeadBase />
                <TableBodyPrice rows={data.marketTwoData.price_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="sector" role="tabpanel" aria-labelledby="sector-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>INCREASE</caption>
                <TableHeadBase />
                <TableBodySector rows={data.marketTwoData.sector_increase} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DECREASE</caption>
                <TableHeadBase />
                <TableBodySector rows={data.marketTwoData.sector_decrease} />
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Summary;
