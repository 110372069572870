import {class_text_danger, format_float} from "../common/util";

function TDGDNN({ticker}) {
  if (!ticker.giaodichnn) {
    return <td></td>
  }
  return (
    <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>
      {format_float(ticker.giaodichnn)}
      <br/>
      {format_float(ticker.giaodichnn > 0 ? ticker.giaodichnn : ticker.giaodichnn * (-1)  * 1e9 / (ticker.priceFlat * 1000) / ticker.volumeFlat * 100, 0) + '%'}
    </td>
  )
}

export default TDGDNN;
