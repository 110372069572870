import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {isNullOrUndefined, renderSignalElement,} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import TDTickerVol from "../components/TDTickerVol";
import TDPrice from "../components/TDPrice";
import CTTService from "../services/CTTService";
import TDGDNN from "../components/TDGDNN";

function Table({title, rows}) {
  return (
    <table className="table table-striped table-hover table-bordered table-sm caption-top">
      <caption>{title}</caption>
      <thead>
      <tr>
        <th className="th-ticker-vol">ticker</th>
        <th className="th-signal">signal</th>
        <th className="th-price">price</th>
        <th>gdnn</th>
      </tr>
      </thead>
      <tbody>
      {
        rows && rows.map((ticker, i) => {
          return (
            <tr key={i}>
              <TDTickerVol ticker={ticker} highlight_own={false}/>
              {renderSignalElement(ticker)}
              <TDPrice ticker={ticker}/>
              <TDGDNN ticker={ticker}/>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

function Follow() {
  const title = 'Follow';
  const [isLoading, setLoad] = useState(true);
  const [ownRows, setOwnRows] = useState([]);
  const [trackingRows, setTrackingRows] = useState([]);

  useEffect(() => {
    async function fetch_follow() {
      const MY_CATALOG = JSON.parse(window.sessionStorage.getItem('my_catalog'))
      const own_tickers = MY_CATALOG['own']
      let own = []
      let tracking = []
      if (!isNullOrUndefined(own_tickers)) {
        own = await CTTService.get_tickers(own_tickers)
      }
      const tracking_tickers = MY_CATALOG['tracking'].reduce((acc, obj) => {
        acc[obj.ticker] = obj;
        return acc;
      }, {});
      if (!isNullOrUndefined(Object.keys(tracking_tickers))) {
        tracking = await CTTService.get_tickers(Object.keys(tracking_tickers))
      }
      own.sort((a, b) => {
        return b.fs_rating - a.fs_rating
      })
      setOwnRows(own)
      tracking.sort((a, b) => {
        return b.fs_rating - a.fs_rating
      })
      setTrackingRows(tracking)
      setLoad(false)
    }
    fetch_follow()
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
            <div className="table-responsive">
              <Table title="OWN" rows={ownRows}/>
            </div>
            <div className="table-responsive">
              <Table title="FOLLOW" rows={trackingRows}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Follow;
